import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {trackPromise} from "react-promise-tracker";
import {sendNotification} from "../commons";

function buildPlants(simulation) {
    let plants = simulation.configuration.plants.map(p => {
        return {
            initialInvestment: 450000,
            yearlyFixedCosts: 10000,
            amortizationYears: 25,
            financedInvestment: 0.7,
            debtMaturityYear: 2042,
            debtInterestRate: 0.03,
            transferPrice: 50,
            type: p['type'],
            index: p['index']
        }
    });
    if(['Hydrogen', 'Hydrogrid'].includes(simulation.configuration.projectType)) {
        plants.push({
            initialInvestment: 450000,
            yearlyFixedCosts: 10000,
            amortizationYears: 25,
            financedInvestment: 0.7,
            debtMaturityYear: 2042,
            debtInterestRate: 0.03,
            transferPrice: 50,
            type: 'Hydrogen Electrolysis',
            index: plants.length + 1
        });
    }
    if(['Data Center'].includes(simulation.configuration.projectType)) {
        plants.push({
            initialInvestment: 450000,
            yearlyFixedCosts: 10000,
            amortizationYears: 25,
            financedInvestment: 0.7,
            debtMaturityYear: 2042,
            debtInterestRate: 0.03,
            transferPrice: 50,
            // annualRevenue:10000000,
            type: 'Data Center',
            index: plants.length + 1
        });
    }
    return plants;
}

function isValidForm(parametersForm) {
    let isValid = true;
    Object.keys(parametersForm).forEach(k => {
        if(k !== 'plants') {
            if(parametersForm[k] === ''){
                isValid = false;
            }
            if(!['name', 'independentVariable', 'dependantVariable'].includes(k) && isNaN(parametersForm[k])){
                isValid = false;
            }
        }else{
            parametersForm[k].forEach(p => {
                Object.keys(p).forEach(pk => {
                    if(pk !== 'type' && (isNaN(p[pk]) || p[pk] === '')){
                        isValid = false;
                    }
                })
            })
        }
    })
    return isValid;
}

function AnalysisParametersForm({
                                simulation,
                                updateSimulation,
                                showAddParameters,
                                setSimulationParametersList,
                                setShowAddParameters,
                                setSimulationInitialValues,
                                parameterId
                          }){

    const defaultSimulationConfig = useMemo(() => ({
        name: "",
        yield: 0.08,
        cpi: 0.02,
        incomeTax: 0.12,
        independentVariable: 'Yield',
        dependantVariable: 'IRR',
        plants: buildPlants(simulation)
    }), [simulation]);

    const [validateForm, setValidateForm] = useState(false);
    const [parametersForm, setParametersForm] = useState(defaultSimulationConfig);

    useEffect(() => {
        if(parameterId){
            trackPromise(
                fetch('/m40alasocho/simulation/' + simulation.id + '/parameters/' + parameterId , {
                    method: 'GET' ,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }))
                .then(res => res.json()).then(data => {
                    const settings = JSON.parse(data.settings.data);
                    setParametersForm({
                        ...settings,
                        "plants": settings["plants"].filter(p=>p)
                    });
                });
        }
    }, [simulation.id, parameterId]);
    
    const createAnalysisParameters = () => {
        setValidateForm(true);
        if(!isValidForm(parametersForm)){
            sendNotification("Please fill all empty fields", "warning");
        }else{
            trackPromise(
                fetch('/m40alasocho/simulation/' + simulation.id + '/parameters', {
                    method: 'POST' ,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...parametersForm
                    })
                }))
                .then(res => res.json()).then(data => {
                if("error" in data){
                    sendNotification(data.error, "danger");
                }else{
                    setSimulationParametersList(data.list);
                    setShowAddParameters(false);
                    setSimulationInitialValues(data.parametersId);
                    updateSimulation(data.simulation);
                    sendNotification('Simulation Parameters Sent', "success");
                }
            });
        }
    }

    const plantsItems = parametersForm.plants.map((p, i) => {
        return (
                <Col key={i} sm={6}>
                    <fieldset key={i} style={{marginTop: "15px"}}>
                        <legend style={{borderBottom: "2px solid #46737c", fontSize: "large"}}>Plant {p['type']}</legend>
                        {
                            ((simulation.configuration.projectType === 'Hydrogen' && p['type'] !== 'Hydrogen Electrolysis') || (simulation.configuration.projectType ==="Data Center" && p['type'] !== 'Data Center')) &&
                            <Row>
                                <Col sm={6}>
                                    <Form.Label>Transfer Price (€/MWh)
                                        <Form.Control type="number" placeholder="Transfer Price (€/MWh)"
                                                      isInvalid={validateForm && (parametersForm.plants[i].transferPrice === '' || !parametersForm.plants[i].transferPrice)} size="sm"
                                                      value={parametersForm.plants[i].transferPrice} onChange={event=>{
                                            setParametersForm({...parametersForm,
                                                plants: [...parametersForm.plants.map((pl, idx) => {
                                                    if(pl['index'] === (i + 1)){
                                                        return {...pl, transferPrice: parseInt(event.target.value)}
                                                    }else{
                                                        return pl;
                                                    }
                                                })]});
                                        }}/>
                                    </Form.Label>
                                </Col>
                            </Row>
                        }
                        {/* {
                            (simulation.configuration.projectType === "Data Center" && p['type'] === 'Data Center') &&
                            <Row>
                                <Col sm={6}>
                                    <Form.Label>Annual Revenue (€M)
                                        <Form.Control
                                            type="number"
                                            placeholder="Annual Revenue (€M)"
                                            isInvalid={validateForm && (parametersForm.plants[i].annualRevenue === '' || !parametersForm.plants[i].annualRevenue)}
                                            size="sm"
                                            value={parametersForm.plants[i].annualRevenue / 1e6} // Convert stored € to €M for display
                                            onChange={event => {
                                                const inputValue = parseFloat(event.target.value) || 0;
                                                setParametersForm({
                                                    ...parametersForm,
                                                    plants: parametersForm.plants.map((pl, idx) => 
                                                        pl['index'] === (i + 1)
                                                            ? { ...pl, annualRevenue: inputValue * 1e6 } // Store in full €
                                                            : pl
                                                    )
                                                });
                                            }}
                                        />
                                    </Form.Label>
                                </Col>
                            </Row>
                        } */}

                        <Row>
                            <Col sm={6}>
                                <Form.Label>Initial Investment (€/MW)
                                    <Form.Control type="number" placeholder="Initial Investment (€/MW)"
                                                  isInvalid={validateForm && (parametersForm.plants[i].initialInvestment === '' || !parametersForm.plants[i].initialInvestment)} size="sm"
                                                  value={parametersForm.plants[i].initialInvestment} onChange={event=>{
                                        setParametersForm({...parametersForm,
                                            plants: [...parametersForm.plants.map((pl, idx) => {
                                                if(pl['index'] === (i + 1)){
                                                    return {...pl, initialInvestment: parseInt(event.target.value)}
                                                }else{
                                                    return pl;
                                                }
                                            })]});
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>Yearly Fixed Costs (€/MW)
                                    <Form.Control type="number" placeholder="Yearly Fixed Costs (€/MW)"
                                                  isInvalid={validateForm && (parametersForm.plants[i].yearlyFixedCosts === '' || !parametersForm.plants[i].yearlyFixedCosts)} size="sm"
                                                  value={parametersForm.plants[i].yearlyFixedCosts} onChange={event=>{
                                        setParametersForm({...parametersForm,
                                            plants: [...parametersForm.plants.map((pl, idx) => {
                                                if(pl['index'] === (i + 1)){
                                                    return {...pl, yearlyFixedCosts: parseInt(event.target.value)}
                                                }else{
                                                    return pl;
                                                }
                                            })]});
                                    }}/>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Label>Amortization Years
                                    <Form.Control type="number" placeholder="Amortization Years"
                                                  isInvalid={validateForm && (parametersForm.plants[i].amortizationYears === '' || !parametersForm.plants[i].amortizationYears)} size="sm"
                                                  value={parametersForm.plants[i].amortizationYears} onChange={event=>{
                                        
                                        setParametersForm({...parametersForm,
                                            plants: [...parametersForm.plants.map((pl, idx) => {
                                                if(pl['index'] === (i + 1)){
                                                    return {...pl, amortizationYears: parseInt(event.target.value)}
                                                }else{
                                                    return pl;
                                                }
                                            })]});
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>Financed Investment (%)
                                    <Form.Control type="number" placeholder="Financed Investment (%)"
                                                  isInvalid={validateForm && (parametersForm.plants[i].financedInvestment === '' || !parametersForm.plants[i].financedInvestment)} size="sm"
                                                  value={parametersForm.plants[i].financedInvestment} onChange={event=>{
                                        
                                        setParametersForm({...parametersForm,
                                            plants: [...parametersForm.plants.map((pl, idx) => {
                                                if(pl['index'] === (i + 1)){
                                                    return {...pl, financedInvestment: parseFloat(event.target.value)}
                                                }else{
                                                    return pl;
                                                }
                                            })]});
                                    }}/>
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Label>Debt Maturity Year
                                    <Form.Control type="number" placeholder="Debt Maturity Year"
                                                  isInvalid={validateForm && (parametersForm.plants[i].debtMaturityYear === '' || !parametersForm.plants[i].debtMaturityYear)} size="sm"
                                                  value={parametersForm.plants[i].debtMaturityYear} onChange={event=>{
                                        
                                        setParametersForm({...parametersForm,
                                            plants: [...parametersForm.plants.map((pl, idx) => {
                                                if(pl['index'] === (i + 1)){
                                                    return {...pl, debtMaturityYear: parseInt(event.target.value)}
                                                }else{
                                                    return pl;
                                                }
                                            })]});
                                    }}/>
                                </Form.Label>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>Debt Interest Rate (%)
                                    <Form.Control type="number" placeholder="Debt Interest Rate"
                                                  isInvalid={validateForm && (parametersForm.plants[i].debtInterestRate === '' || !parametersForm.plants[i].debtInterestRate)} size="sm"
                                                  value={parametersForm.plants[i].debtInterestRate} onChange={event=>{
                                        
                                        setParametersForm({...parametersForm,
                                            plants: [...parametersForm.plants.map((pl, idx) => {
                                                if(pl['index'] === (i + 1)){
                                                    return {...pl, debtInterestRate: parseFloat(event.target.value)}
                                                }else{
                                                    return pl;
                                                }
                                            })]});
                                    }}/>
                                </Form.Label>
                            </Col>
                        </Row>
                    </fieldset>
                   </Col>)
    });

    return (
        <Modal show={showAddParameters} onHide={()=>setShowAddParameters(false)} animation={false} size={"xl"}>
            <Modal.Header closeButton>
                <Modal.Title>Valuation parameters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <fieldset>
                    <form>
                        <div>
                            <Row>
                                <Col sm={8}>
                                    <fieldset>
                                        <legend style={{fontSize: "small", borderBottom: "1px" +
                                                " solid #46737c"}}>Configuration</legend>
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Label>Name
                                                    <Form.Control type="string" placeholder="Name"
                                                                  maxLength={50}
                                                                  isInvalid={validateForm && parametersForm.name === ''} size="sm"
                                                                  value={parametersForm.name} onChange={event=>{

                                                        setParametersForm({...parametersForm, name: event.target.value});
                                                    }}/>
                                                </Form.Label>
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Label>Yield
                                                    <Form.Control type="number" placeholder="Yield" isInvalid={validateForm && (parametersForm.yield === '' || !parametersForm.yield)}
                                                                  size="sm" value={parametersForm.yield} onChange={event=>{

                                                        setParametersForm({...parametersForm, yield: parseFloat(event.target.value)});
                                                    }}/>
                                                </Form.Label>
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Label>CPI
                                                    <Form.Control type="number" placeholder="CPI" isInvalid={validateForm && (parametersForm.cpi === '' || !parametersForm.cpi)} size="sm"
                                                                  value={parametersForm.cpi} onChange={event=>{

                                                        setParametersForm({...parametersForm, cpi: parseFloat(event.target.value)});
                                                    }}/>
                                                </Form.Label>
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Label>Income Tax
                                                    <Form.Control type="number" placeholder="Income Tax"
                                                                  isInvalid={validateForm && parametersForm.incomeTax === '' && (parametersForm.incomeTax === '' || !parametersForm.incomeTax)} size="sm" value={parametersForm.incomeTax} onChange={event=>{

                                                        setParametersForm({...parametersForm, incomeTax: parseFloat(event.target.value)});
                                                    }}/>
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Col>
                                <Col sm={4}>
                                    <fieldset>
                                        <legend style={{fontSize: "small", borderBottom: "1px solid #46737c"}}>Sensitivity analysis</legend>
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Label>Independent Variable
                                                    <Form.Control as="select" aria-label="Independent variable" size="sm" value={parametersForm.independentVariable}
                                                                  onChange={event=>{
                                                                      setParametersForm({...parametersForm, independentVariable: event.target.value});
                                                                  }}>
                                                        <option value="Yield">Yield</option>
                                                        <option value="CPI">CPI</option>
                                                        <option value="IncomeTax">Income TAX</option>
                                                        <option value="InitialInvestment_{}">Initial Investment Cost</option>
                                                    </Form.Control>
                                                </Form.Label>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Label>Dependent Variable
                                                    <Form.Control as="select" aria-label="Dependent variable" size="sm" value={parametersForm.dependantVariable}
                                                                  onChange={event=>{
                                                                      setParametersForm({...parametersForm, dependantVariable: event.target.value});
                                                                  }}>
                                                        <option value="IRR">IRR</option>
                                                        <option value="LCOE (€/MWh)">LCOE</option>
                                                        <option value="Equity NPV">Equity NPV</option>
                                                        <option value="Enterprise NPV">Enterprise NPV</option>
                                                        {
                                                            simulation.configuration.projectType === 'Hydrogen' &&
                                                            <option value="LCOH (€/MWh)">LCOH</option>
                                                        }
                                                    </Form.Control>
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                    </fieldset>

                                </Col>
                            </Row>
                            <Row>
                                {plantsItems}
                            </Row>
                        </div>
                    </form>
                </fieldset>
            </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>setShowAddParameters(false)}>
                Close
            </Button>
            {
                !parameterId &&

                <Button variant="primary" onClick={createAnalysisParameters}>
                    Run Analysis
                </Button>
            }
        </Modal.Footer>
    </Modal>
    )
}

export default AnalysisParametersForm;