/* eslint-disable */
import {
    Brush,
    CartesianGrid,
    Label,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import React, {useCallback, useEffect, useState} from "react";
import {
    buildDataAggregated,
    buildSeries,
    findColorByKey,
    getFormattedNumber,
    getFormattedValue,
    getLabelByAggregation,
    getLineVisibility,
    noDataAvailableStyle,
    sendNotification
} from "../commons";
import moment from "moment-timezone";
import {FaImage} from "react-icons/fa";
import { MdRestore} from "react-icons/md";
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from "file-saver";

function LineChartComponent({title, data, brushKey, xAxisKey,
    yAxisUnit, yAxisRight, yAxisType, aggregation, decimals, noDataMessage, isDomainPercentage,
                            yAxisLabel}) {
    const backup = console.warn;

    console.warn = function filterWarnings(msg) {
        const supressedWarnings = ['The width(0) and height(0) of chart should be greater than 0'];

        if (!supressedWarnings.some(entry => msg.includes(entry))) {
            backup.apply(console, arguments);
        }
    };


    const [lineVisibility, setLineVisibility] = useState({});
    // const [getPng, { ref }] = useCurrentPng();
    const [getPng, { ref, isLoading }] = useCurrentPng();

    useEffect(()=>{
    }, [data]);

    let keys = [];
    if(data.length > 0){
        data.forEach(d=>{
            Object.keys(d).filter(k => k !== "time" && !k.startsWith("_")).forEach(a => {
               if(!keys.includes(a)){
                   keys.push(a);
               }
            });
        })
    }

    function updateVisibility(event) {
        const lineStatus = event["dataKey"].trim() in lineVisibility ? lineVisibility[event["dataKey"].trim()] : null;
        const visibility = (lineStatus === "visible" || lineStatus == null) ? "hidden" : "visible";
        setLineVisibility({...lineVisibility, [event["dataKey"].trim()]: visibility});
        sendNotification(event["dataKey"].trim() + " is " + visibility, "success");
    }

    let brush = null;
    if(brushKey){
        brush = <Brush tickFormatter={(val) => {
                    return moment.unix(val).tz("GMT").format('DD-MM-YY');
                }} dataKey={brushKey} />
    }

    let xAxis;
    if(xAxisKey === "index") {
        const rangeFrom = data.length > 0 ? 100 / data.length : 0;
        xAxis = <XAxis dataKey={xAxisKey} angle="15" dy={10} height={45} type="number"
                       allowDataOverflow={true}
                       tickFormatter={(value) => value.toFixed(0)}
                       domain={[rangeFrom, 100]}
                       unit={"%"}/>;
    }
    else{
        xAxis = <XAxis dataKey={xAxisKey}
                       tickFormatter={(item) => getLabelByAggregation(item, aggregation)}
                       angle="15" dy={10} height={45} />;
    }

    let yAxisProps = {};
    if(yAxisLabel){
        yAxisProps = {
            label: {
                value: yAxisLabel,
                position: "left"
            }
        }
    }
    if(isDomainPercentage){
        yAxisProps["domain"] = [0, 100];
    }

    function getLabelText(val) {
        if(xAxisKey === "index") {
            return (val).toFixed(1) + " %";
        }
        else{
            return getLabelByAggregation(val, aggregation);
        }
    }

    let series = [];
    let aggData = [];
    if(xAxisKey === "index" && data.length > 0) {
        buildSeries(data, series);
        buildDataAggregated(data, series, aggData);
    }

    let yAxis = yAxisRight ? <YAxis width={80} yAxisId="right" orientation="right"
                                    tick={{ fontSize: 10, }} unit={yAxisRight["unit"]}>
                                        <Label
                                            value={yAxisRight["label"]}
                                            angle={-90}
                                            position='outside'
                                            fill='#676767'
                                            fontSize={14}
                                        />
                                    </YAxis> : null;


    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
          // Download with FileSaver
            FileSaver.saveAs(png, title + '.png');
        }
    }, [getPng, title]);


    const iconsStyle = {
        marginLeft:"15px",
        cursor: "pointer"
    };

    const renderColorfulLegendText = (val, entry) => {
        return getLineVisibility(val, entry);
    };

    const message = noDataMessage ? noDataMessage : 'No data available';

    return (
        <div style={{
            width: "100%",
            height: "450px"
        }}>
            <h6>
                {title}
                <MdRestore style={iconsStyle} onClick={()=>setLineVisibility({})} title="Restore to initial state"/>
                <FaImage style={iconsStyle} onClick={()=>handleDownload()} title="Download chart image"/>
            </h6>
            <ResponsiveContainer width='99%'>
                {data.length > 0 ?
                    (
                        <LineChart data={xAxisKey === "index" ? aggData : data} height={500} minWidth={300} ref={ref}
                                margin={{top: 5, right: 30, left: 20, bottom: 5}} connectNulls={false}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            {xAxis}
                            <YAxis unit={yAxisUnit} yAxisId="left"
                                   {...yAxisProps}
                                   tickFormatter={(value) => {
                                       return getFormattedNumber(value, decimals);
                                   }}
                                   type={yAxisType ? yAxisType : "number"}/>
                            {yAxis}
                            <Tooltip labelFormatter={(val) => getLabelText(val)} formatter={(value, name, props) => {
                                if(name.includes("(Ton/MWh)")){
                                    props["unit"] = yAxisUnit;
                                }
                                else if(yAxisRight && "unit" in yAxisRight){
                                    props["unit"] = yAxisRight["unit"]
                                }
                                const labelName = `_${name}` in props["payload"] ? props["payload"][`_${name}`] : name;
                                return [getFormattedValue(props, value, decimals), labelName];
                            }} animationDuration={0}/>
                            <Legend onClick={(event) => {
                                                updateVisibility(event);
                                            }}
                                    wrapperStyle={{position: 'relative', marginTop: '0px'}}
                                    formatter={renderColorfulLegendText} />
                            {keys.map((f, idx) => {
                                return <Line key={f}
                                             yAxisId={yAxisRight && yAxisRight["keys"].includes(f) ? "right" : "left"}
                                             visibility={f in lineVisibility ? lineVisibility[f] : "visible"}
                                             type="monotone"
                                             dataKey={(f in lineVisibility && lineVisibility[f] === "visible") || !(f in lineVisibility) ? f : (f + " ")}
                                             dot={false} stroke={findColorByKey(f, idx)} width="100%" height="100%"
                                             unit={yAxisUnit} name={f}/>
                            })}
                            {brush}
                        </LineChart>
                    ) : (
                        <h5 style={noDataAvailableStyle}>
                            {message}
                        </h5>
                    )
                }

            </ResponsiveContainer>
        </div>
    )
}
export default LineChartComponent;
