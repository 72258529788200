import {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {Table} from "react-bootstrap";
import {formatDateFromString, getFormattedNumber} from "../../commons";

function RegulatorDataCenterSummary({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation,
                          }){

    const [regulatorData, setRegulatorData] = useState(null);

    useEffect(() => {
        if(simulationId && simulationInitialValues && typeName) {
            trackPromise(
                fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/' + typeName + '?'
                    + 'aggregation=' + aggregation))
                .then(res => res.json()).then(data => {
                if (data["data"].length === 1) {
                    setRegulatorData({...JSON.parse(data["data"][0]["data"])});
                }
            });
        }

    }, [simulationId, aggregation, typeName, simulationInitialValues]);


    console.log(regulatorData);

    let summaryInfo = null;
    if (regulatorData && viewName === "Regulator's view - Summary"){
        summaryInfo = (
            <div>
                <fieldset>
                    <legend className={"text-left"}>
                        Project's metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Average Electricity Price from Grid (€/MWh)</th>
                            <th>Average Electricity Price from Plants (€/MWh)</th>
                            <th>Yearly consumption (MWh)</th>
                            <th>Yearly water consumption (Ml)</th>
                            <th>Consumption from grid (%)</th>
                            <th>Consumption from plants (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{getFormattedNumber(regulatorData['Average Electricity Price from Grid (€/Mwh)'],2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average Electricity Price from Plants (€/Mwh)'],2)}</td>
                            <td>{regulatorData['Yearly consumption MWh']}</td>
                            <td>
                                {regulatorData['Yearly Water Consumption (Ml)']
                                    ? getFormattedNumber(regulatorData['Yearly Water Consumption (Ml)'], 2)
                                    : null}
                            </td>
                            <td>{getFormattedNumber(regulatorData['Consumption from grid (%)'],1)}</td>
                            <td>{getFormattedNumber(regulatorData['Consumption from plants (%)'],1)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </fieldset>

                <fieldset style={{marginTop: "20px"}}>
                    <legend className={"text-left"}>
                        Plant's metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Plant</th>
                            <th>Average price received (€/MWh)</th>
                            <th>Captured Market Price (€/MWh)</th>
                            {/* <th>Regulated avg. subsidy (€/MWh)</th> */}
                            <th>Average cost (€/MWh)</th>
                            <th>Variable cost (€/MWh)</th>
                            <th>Full Load Hours (h)</th>
                            <th>Production to Data Center (%)</th>
                            <th>Production to the Grid (%)</th>
                            <th>Yearly Excess Production (MWh)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Project</td>
                            <td>{getFormattedNumber(regulatorData['Average price received']["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Captured market price']["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average cost"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Variable cost"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Equivalent full load hours production (h)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average Production to Data Center (%)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average Production to Grid (%)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Yearly excess production (MWh)"]["Total"], 2)}</td>
                        </tr>
                        {
                            simulation.configuration.plants.map((p, idx) => {
                                const plant_index = p['index'];
                                return (<tr key={idx}>
                                            <td>{p['type']}</td>
                                            <td>{getFormattedNumber(regulatorData['Average price received']['VAL_' + plant_index], 2)}</td>   
                                            <td>{'Captured market price' in regulatorData ? getFormattedNumber(regulatorData['Captured market price']['VAL_' + plant_index], 2) : 0}</td>
                                            {/* <td>
                                                {getFormattedNumber(regulatorData?.['Regulated avg. subsidy']?.['VAL_' + plant_index] ?? 0, 2)}
                                            </td> */}
                                            <td>{getFormattedNumber(regulatorData['Average cost']['VAL_' + plant_index], 2)}</td>
                                            <td>{getFormattedNumber(regulatorData['Variable cost']['VAL_' + plant_index], 2)}</td>
                                            <td>{getFormattedNumber(regulatorData['Equivalent full load hours production (h)']['VAL_' + plant_index], 2)}</td>
                                            <td>{getFormattedNumber(regulatorData['Average Production to Data Center (%)']['VAL_' + plant_index], 2)}</td>
                                            <td>{getFormattedNumber(regulatorData['Average Production to Grid (%)']['VAL_' + plant_index], 2)}</td>
                                            <td>{getFormattedNumber(regulatorData["Yearly excess production (MWh)"]["Total"], 2)}</td>
                                        </tr>)
                            })
                        }
                        </tbody>
                    </Table>
                </fieldset>
            </div>
        )
    }


    
    if (regulatorData && viewName === "Investor's view - Summary") {
        summaryInfo = (
            <>
                <fieldset>
                    <legend className="text-left">Project's metrics</legend>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>LCOE (€/MWh)</th>
                                <th>Payback date</th>
                                <th>Payback period (years)</th>
                                <th>Enterprise NPV (M€)</th>
                                <th>Equity NPV (M€)</th>
                                <th>IRR (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{getFormattedNumber(regulatorData['LCOE (€/MWh)'], 2)}</td>
                                <td>{formatDateFromString(regulatorData['Payback date'], 'yyyy-MM-dd')}</td>
                                <td>{getFormattedNumber(regulatorData['Payback period'], 1)}</td>
                                <td>{getFormattedNumber(regulatorData['Enterprise NPV'] / 1_000_000, 2)}</td>
                                <td>{getFormattedNumber(regulatorData['Equity NPV'] / 1_000_000, 2)}</td>
                                <td>{getFormattedNumber(regulatorData['IRR'] * 100, 2)}%</td>
                            </tr>
                        </tbody>
                    </Table>
                </fieldset>
    
                <fieldset>
                    <legend className="text-left">Plants' metrics</legend>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Plant</th>
                                <th>LCOE (€/MWh)</th>
                                <th>Payback date</th>
                                <th>Payback period (years)</th>
                                <th>Enterprise NPV (M€)</th>
                                <th>Equity NPV (M€)</th>
                                <th>IRR (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {simulation.configuration.plants.map((p, idx) => {
                                const plant_index = p['index'] - 1;   
                                return (
                                    <tr key={idx}>
                                    <td>{p.type}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][plant_index]['LCOE (€/MWh)'], 2)}</td>
                                    <td>{formatDateFromString(regulatorData['plants'][plant_index]['Payback date'], 'yyyy-MM-dd')}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][plant_index]['Payback period'], 1)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][plant_index]['Enterprise NPV']/ 1_000_000, 2)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][plant_index]['Equity NPV']/ 1_000_000, 2)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][plant_index]['IRR'], 2)}</td>
                                    </tr>
                                );
                            })}
                            <tr key={simulation.configuration.plants.length}>
                                    <td>Data Center</td>
                                    <td>-</td>
                                    <td>{formatDateFromString(regulatorData['plants'][simulation.configuration.plants.length]['Payback date'], 'yyyy-MM-dd')}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['Payback period'], 1)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['Enterprise NPV']/ 1_000_000, 2)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['Equity NPV']/ 1_000_000, 2)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['IRR'], 2)}</td>
                                </tr> 
                        </tbody>
                    </Table>
                </fieldset>
            </>
        );
    }
      

    return regulatorData ? (
        <div>
            <h3>{viewName}</h3>
            {summaryInfo}
        </div>
    ) : <h3>Loading...</h3>
}

export default RegulatorDataCenterSummary;