import {useEffect, useState} from "react";
import {trackPromise} from "react-promise-tracker";
import {Table} from "react-bootstrap";
import {formatDateFromString, getFormattedNumber} from "../../commons";
function RegulatorHydrogenSummary({
                              viewName,
                              typeName,
                              aggregation,
                              simulationId,
                              simulationInitialValues,
                              simulation
                          }){

    const [regulatorData, setRegulatorData] = useState(null);

    useEffect(() => {
        if(simulationId && simulationInitialValues && typeName) {
            trackPromise(
                fetch('/m40alasocho/investment/' + simulationId + '/settings/' + simulationInitialValues + '/results/' + typeName + '?'
                    + 'aggregation=' + aggregation))
                .then(res => res.json()).then(data => {
                if (data["data"].length === 1) {
                    setRegulatorData({...JSON.parse(data["data"][0]["data"])});
                }
            });
        }
    }, [simulationId, aggregation, typeName, simulationInitialValues]);

    console.log(regulatorData);

    let summaryInfo = null;
    if (regulatorData && viewName === "Regulator's view - Summary"){
        summaryInfo = (
            <div>
                <fieldset>
                    <legend className={"text-left"}>
                        Plants metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Total H2 production (Tons)</th>
                            <th>Yearly water consumption (Ml)</th>
                            <th>Average Electricity Price from Grid (€/MWh)</th>
                            <th>Average Electricity Price from Plants (€/MWh)</th>
                            <th>Annual operating hours (h)</th>
                            <th>Consumption from grid (%)</th>
                            <th>Consumption from plants (%)</th>
                            <th>Average Cost H2 (€/Kg)</th>
                            <th>Variable Cost H2 (€/Kg)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{getFormattedNumber(regulatorData['Total H2 production (tons)'], 2)}</td>
                            <td>
                                {regulatorData['Yearly Water Consumption (Ml)']
                                    ? getFormattedNumber(regulatorData['Yearly Water Consumption (Ml)'], 2)
                                    : null}
                            </td>
                            <td>{getFormattedNumber(regulatorData['Average Electricity Price from Grid (€/Mwh)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average Electricity Price from Plants (€/Mwh)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Annual operating hours (h)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Consumption from grid (%)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Consumption from plants (%)'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Average cost H2'], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Variable cost H2'], 2)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </fieldset>

                <fieldset>
                    <legend className={"text-left"}>
                        Renewable Plant's metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Plant's</th>
                            <th>Average price received (€/MWh)</th>
                            <th>Captured market price (€/MWh)</th>
                            <th>Average cost (€/MWh)</th>
                            <th>Variable cost (€/MWh)</th>
                            <th>Eq. full load hours production to H2 (h)</th>
                            <th>Eq. full load hours production to grid (h)</th>
                            <th>Yearly excess production (MWh)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Project</td>
                            <td>{getFormattedNumber(regulatorData['Average price received']["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData['Captured market price']["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Average cost"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Variable cost"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Equivalent full load hours production to H2 (MWh)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Equivalent full load hours production to grid (MWh)"]["Total"], 2)}</td>
                            <td>{getFormattedNumber(regulatorData["Yearly excess production (MWh)"]["Total"], 2)}</td>
                        </tr>
                        {
                            simulation.configuration.plants.map((p, idx) => {
                                const plant_index = p['index'];
                                return (
                                    <tr key={idx}>
                                        <td>{p['type']}</td>
                                        <td>{getFormattedNumber(regulatorData['Average price received']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Captured market price']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Average cost']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Variable cost']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Equivalent full load hours production to H2 (MWh)']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData['Equivalent full load hours production to grid (MWh)']['VAL_' + plant_index], 2)}</td>
                                        <td>{getFormattedNumber(regulatorData["Yearly excess production (MWh)"]['VAL_' + plant_index], 2)}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </Table>
                </fieldset>

                <fieldset>
                    <legend className={"text-left"}>
                        Regulator's metrics
                    </legend>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>NPV of regulator revenue (M€)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{getFormattedNumber(regulatorData['NPV of regulator revenue'] / 1000000, 0)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </fieldset>
            </div>
        )
    }

    if (regulatorData && viewName === "Investor's view - Summary") {
        summaryInfo = (
            <>
                <fieldset>
                    <legend className={"text-left"}>Project's metrics</legend>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>LCOH (€/kg)</th>
                                <th>Payback date</th>
                                <th>Payback period (years)</th>
                                <th>Enterprise NPV (M€)</th>
                                <th>Equity NPV (M€)</th>
                                <th>IRR (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{getFormattedNumber(regulatorData['LCOH (€/kg)'], 2)}</td>
                                <td>{regulatorData['Payback date']}</td>
                                <td>{regulatorData['Payback period']}</td>
                                <td>{getFormattedNumber(regulatorData['Enterprise NPV'] / 1_000_000, 2)}</td>
                                <td>{getFormattedNumber(regulatorData['Equity NPV'] / 1_000_000, 2)}</td>
                                <td>{getFormattedNumber(regulatorData['IRR'], 2)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </fieldset>
    
                <fieldset>
                    <legend className={"text-left"}>Plant's metrics</legend>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Plant</th>
                                <th>LCOE (€/MWh)</th>
                                <th>LCOH (€/kg)</th>
                                <th>Payback date</th>
                                <th>Payback period (years)</th>
                                <th>Enterprise NPV (M€)</th>
                                <th>Equity NPV (M€)</th>
                                <th>IRR (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {simulation.configuration.plants.map((p, idx) => {
                                const plantIndex = p['index'] - 1;
                                const plantData = regulatorData['plants'][plantIndex];
    
                                return (
                                    <tr key={idx}>
                                        <td>{p.type}</td>
                                        <td>{getFormattedNumber(plantData?.['LCOE (€/MWh)'], 2)}</td>
                                        <td>-</td>
                                        <td>{formatDateFromString(plantData?.['Payback date'], 'yyyy-MM-dd')}</td>
                                        <td>{getFormattedNumber(plantData?.['Payback period'], 1)}</td>
                                        <td>{getFormattedNumber(plantData?.['Enterprise NPV'] / 1_000_000, 2)}</td>
                                        <td>{getFormattedNumber(plantData?.['Equity NPV']/ 1_000_000, 2)}</td>
                                        <td>{getFormattedNumber(plantData?.['IRR'], 2)}</td>
                                    </tr>
                                );
                            })}
    
                            <tr key={simulation.configuration.plants.length}>
                                    <td>Electrolyzer</td>
                                    <td>-</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['LCOE (€/MWh)'], 2)}</td> 
                                    <td>{formatDateFromString(regulatorData['plants'][simulation.configuration.plants.length]['Payback date'], 'yyyy-MM-dd')}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['Payback period'], 1)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['Enterprise NPV']/ 1_000_000, 2)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['Equity NPV']/ 1_000_000, 2)}</td>
                                    <td>{getFormattedNumber(regulatorData['plants'][simulation.configuration.plants.length]['IRR'], 2)}</td>
                                </tr>           
                        </tbody>
                    </Table>
                </fieldset>
            </>
        );
    }
    


    return regulatorData ? (
        <div>
            <h3>{viewName}</h3>
            {summaryInfo}
        </div>
    ) : <h3>Loading...</h3>
}

export default RegulatorHydrogenSummary;